
class SubCompanyApi {

    getList() {
        var url = apiUrl + 'subcompanies';
        return api.call('get', url)
            .then(({ data }) => {
                return data
            });
    }

    get(id) {
        var url = apiUrl + 'subcompanies/';
        return api.call('get', url + id)
            .then(({ data }) => {
                return data
            });
    }
 
    create(data) {
        var url = apiUrl + 'subcompanies';
        return api.call('post', url, data)
            .then(({ data }) => {
                return data
            });

    }
    update(data) {
        var url = apiUrl + 'subcompanies/';
        return api.call('put', url + data.id, data)
            .then(({ data }) => {
                return data
            });
    }
    delete(id) {
        var url = apiUrl + 'subcompanies/';
        return api.call('delete', url + id)
            .then(({ data }) => {
                return data
            });
    }

    addBankAccount(id, data) {
        var url = apiUrl + 'subcompanies/AddBankAccount/';
        return api.call('put', url + id, data)
            .then(({ data }) => {
                return data
            });
    }
    
    removeBankAccount(id) {
        var url = apiUrl + 'subcompanies/RemoveBankAccount/' + id;
        return api.call('put', url)
            .then(({ data }) => {
                return data
            });
    }
    updateBankAccount(id, data) {
        var url = apiUrl + 'subcompanies/UpdateBankAccount/';
        return api.call('put', url + id, data)
            .then(({ data }) => {
                return data
            });
    }
    


}

export default SubCompanyApi;